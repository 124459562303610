import store from '../store';

const HeaderBuilder = {
    build: (omitContentType = false) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (omitContentType) {
            delete headers['Content-Type'];
        }
        if (store.state.token && store.state.token.bearer) {
            headers.Authorization = 'Bearer ' + store.state.token.bearer;
        }
        return headers;
    }
};

export default HeaderBuilder;